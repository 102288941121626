/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
import ModalCount from "./ModalCount";

import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Edit, Visibility } from "@material-ui/icons";
import { toast } from "react-toastify";
import useStyles from "./styles";
import api from "../../../services/api";
import history from "../../../services/history";
import Modal from "./Modal";
import { useCallback } from "react";

function Tasks() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [total, setTotal] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [user, setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 1,
  });
  const [openCount, setOpenCount] = useState(false);
  const [count, setCount] = useState([]);
  const [countUser, setCountUser] = useState(null);

  const [sortBy, setSortBy] = useState("count");

  async function getUsers(page) {
    try {
      setLoading(true);
      const response = await api.get("/agent/corrected", {
        params: {
          sort: sortBy,
          order: sortBy === "count" ? "desc" : "asc",
          search,
        },
      });

      setUsers(response.data);

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading(false);
    }
  }

  async function selectUser(user) {
    try {
      setLoading2(true);
      const response = await api.get(`/user`, {
        params: {
          id: user._id,
        },
      });

      setUser(response.data);
      setOpen(true);

      setLoading2(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading2(false);
    }
  }

  async function getCount(user) {
    try {
      setCount(user.answersCorrected);
      setCountUser(user);

      setOpenCount(true);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  const handleChange = (event, value) => {
    getUsers(value);
  };

  const reload = useCallback(() => {
    if (openCount) {
      return;
    }

    getUsers();
  }, [openCount]);

  React.useEffect(() => {
    getUsers(1);
  }, [sortBy]);

  React.useEffect(() => {
    const id = setInterval(function () {
      reload();
    }, 1000 * 60 * 2);

    return () => clearInterval(id);
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Corretores
                    {total && (
                      <Typography
                        style={{ marginLeft: 10 }}
                      >{`total: ${total}`}</Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid item>
                  <div style={{ width: 300 }}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Ordenação
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sortBy}
                        variant="outlined"
                        label="Ordenar por"
                        style={{ width: "100%", marginTop: 20 }}
                        onChange={(e) => setSortBy(e.target.value)}
                        fullWidth
                      >
                        <MenuItem value={"count"}>Correções (qtd)</MenuItem>
                        <MenuItem value={"name"}>ordem alfabética</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        label="Pesquisar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading2}
                        onClick={() => getUsers()}
                      >
                        {loading2 ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "#ffff" }}
                          />
                        ) : (
                          "Atualizar"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell align="center">Registro</TableCell>
                      <TableCell align="center">Grupo de correção</TableCell>
                      <TableCell align="right">Quantidade corrigida</TableCell>
                      <TableCell align="center">Açoes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell component="th" scope="row">
                          {user?._id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user?.name}
                        </TableCell>
                        <TableCell align="center">{user?.register}</TableCell>
                        <TableCell align="center">
                          {user?.permissons?.name || "Sem grupo de permissão"}
                        </TableCell>
                        <TableCell align="center">{user?.count || 0}</TableCell>

                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton onClick={() => getCount(user)}>
                              <Visibility />
                            </IconButton>
                            <IconButton onClick={() => selectUser(user)}>
                              <Edit />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* <Grid item xs={12}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handleChange}
              />
            </Grid> */}
          </Grid>
        )}
      </Paper>

      <Modal
        open={open}
        close={() => {
          setUser(false);
          setOpen(false);
        }}
        Att={() => getUsers()}
        data={user}
      />

      <ModalCount
        open={openCount}
        close={() => setOpenCount(false)}
        data={count}
        agent={countUser}
      />
    </Container>
  );
}

export default Tasks;
