import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import logo from "../../../assets/logo.svg";
import dateMask from "../../../components/maskInput/date";
import history from "../../../services/history";
import { signInRequest } from "../../../store/modules/auth/actions";
import useStyles from "./styles";

import Input from "../../../components/Input";

import { Form } from "@unform/web";
import * as Yup from "yup";
import validator from "./validators";

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const classes = useStyles();
  const formRef = useRef();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handlechange = (e, name) => {
    setData({ ...data, [name]: e.target.value });
  };

  async function submit() {
    formRef.current.setErrors({});

    try {
      await validator(data);

      dispatch(signInRequest(data));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.descriptionDiv}>
            <Typography className={classes.description}>
              Joti para Admins
            </Typography>
            <Hidden smDown>
              <Typography className={classes.description}>
                Preencha os campos para entrar na plataforma
              </Typography>
            </Hidden>
          </Grid>

          <Grid item xs={12}>
            <Form ref={formRef} onSubmit={submit} className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    name="email"
                    label="Registro ou e-mail"
                    value={data.email}
                    onChange={(e) => handlechange(e, "email")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    name="password"
                    label="Senha"
                    value={data.password}
                    onChange={(e) => handlechange(e, "password")}
                    type="password"
                  />
                </Grid>

                <Grid item xs={12}>
                  {loading ? (
                    <div className={classes.loading}>
                      <CircularProgress
                        style={{ color: "#744EAA" }}
                        size={40}
                      />
                    </div>
                  ) : (
                    <Button
                      className={classes.btn1}
                      fullWidth
                      disabled={loading}
                      type="submit"
                    >
                      Entrar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Grid>

          <Hidden sm smDown>
            <Grid item xs={12}>
              <Typography className={classes.copyright}>
                Copyright ©Joti 2024
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>

      {/* <Hidden md mdUp>
        <Grid item xs={12}>
          <Typography className={clsx(classes.copyright, classes.white)}>
            Copyright ©Joti 2021
          </Typography>
        </Grid>
      </Hidden> */}
    </Container>
  );
}
