/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Dialog,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  CircularProgress,
  Box,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { format } from "date-fns";

import api from "../../../../services/api";

import useStyles from "../styles";
import { useState } from "react";
import { toast } from "react-toastify";

const Row = ({ count, agent }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      console.log(count);

      const response = await api.get(`/agent/${agent}/answer`, {
        params: {
          task: count._id.id,
        },
      });

      setData(response.data.data);
      setOpen(true);
    } catch (err) {
      toast.error("Erro ao buscar dados");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow /* key={user._id} */>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (loading) return;

              if (open) {
                setOpen(false);

                return;
              }

              getData();
            }}
            style={{ marginRight: 10 }}
          >
            {loading ? (
              <CircularProgress size={22} />
            ) : open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {`${count?._id?.number} - ${count?._id?.task}`}
        </TableCell>
        <TableCell align="right"> {count.count} </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Correções
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Nota</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((dt) => (
                    <TableRow key={dt.getCorrected}>
                      <TableCell component="th" scope="row">
                        {format(
                          new Date(dt.getCorrected),
                          "dd/MM/yyyy HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell>{dt.grade}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

function Modal({ open, close, data, agent }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      style={{ padding: 20 }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" gutterBottom component="div">
          {agent?.name}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {data.length === 0 ? (
          <Typography>Nenhuma tarefa corrigida</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tarefa</TableCell>
                  <TableCell align="right">Correções</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((count) => (
                  <Row count={count} agent={agent._id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
