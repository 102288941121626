/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
import { Pagination } from "@material-ui/lab";
import React from "react";
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { toast } from "react-toastify";
import useStyles from "./styles";
import api from "../../../services/api";

function Tasks() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [total, setTotal] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [teams, setTeams] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 1,
  });

  async function getAnswers(page) {
    try {
      setLoading(true);
      const response = await api.get("/answers", {
        params: {
          filter: search,
          perPage: 10,
          page: page || 1,
        },
      });

      setTeams(response.data.data);
      setTotal(response.data.totalData);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading(false);
    }
  }

  async function selectTeam(team) {
    /*  try {
      setLoading2(true);

      history.push(`/team/${team._id}`);

      setLoading2(false);
    } catch (error) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');

      setLoading2(false);
    } */
  }

  const handleChange = (event, value) => {
    getAnswers(value);
  };

  React.useEffect(() => {
    getAnswers(1);
  }, []);

  async function deleteAnswer(answer) {
    try {
      setLoading(true);
      await api.delete(`/answers/${answer}`);

      toast.success("Resposta excluida com sucesso.");

      setLoading(false);
      getAnswers();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      }

      setLoading(false);
    }
  }

  return (
    <Container>
      <Paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Tarefas
                    {total && (
                      <Typography
                        style={{ marginLeft: 10 }}
                      >{`total: ${total}`}</Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        label="Pesquisar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading2}
                        onClick={() => getAnswers()}
                      >
                        {loading2 ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "#ffff" }}
                          />
                        ) : (
                          "Atualizar"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Equipe</TableCell>
                      <TableCell align="center">Tipo</TableCell>
                      <TableCell align="center">Tarefa</TableCell>
                      <TableCell align="center">corretor</TableCell>
                      <TableCell align="center">Correcão</TableCell>
                      <TableCell align="right">Açoes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.map((item) => (
                      <TableRow key={item._id}>
                        <TableCell component="th" scope="row">
                          {item.team.name}
                        </TableCell>
                        <TableCell align="center"> {item.team.type}</TableCell>
                        <TableCell align="center"> {item.task.title}</TableCell>

                        <TableCell align="center">
                          {" "}
                          {item.agentId ?? ""}
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            color="secondary"
                            link
                            href={`https://corretor.jotajoti.com.br/task/${item.task._id}/${item._id}`}
                            target="_blank"
                          >
                            Correcão
                          </Button>
                        </TableCell>

                        <TableCell align="right">
                          {/* <IconButton onClick={() => selectTeam(item)}>
                            <Edit />
                          </IconButton> */}
                          <IconButton onClick={() => deleteAnswer(item._id)}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
}

export default Tasks;
