/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
import React from 'react';
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { toast } from 'react-toastify';
import useStyles from './styles';
import api from '../../../services/api';
import history from '../../../services/history';
import Modal from './Modal';
import ModalAdd from './ModalAdd';

function Tasks() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [total, setTotal] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  const [permission, setPermission] = React.useState(null);

  async function getTeams() {
    try {
      setLoading(true);
      const response = await api.get('/permissons', {});

      setTeams(response.data.data);
      setTotal(response.data.totalData);

      setLoading(false);
    } catch (error) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');

      setLoading(false);
    }
  }

  async function selectTeam(team) {
    try {
      setLoading2(true);

      history.push(`/team/${team._id}`);

      setLoading2(false);
    } catch (error) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');

      setLoading2(false);
    }
  }

  React.useEffect(() => {
    getTeams();
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Permissoes
                    {total ? (
                      <Typography
                        style={{ marginLeft: 10 }}
                      >{`total: ${total}`}</Typography>
                    ): ""}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        label="Pesquisar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading2}
                        onClick={() => getTeams()}
                      >
                        {loading2 ? (
                          <CircularProgress
                            size={20}
                            style={{ color: '#ffff' }}
                          />
                        ) : (
                          'Atualizar'
                        )}
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        onClick={() => setOpenCreate(true)}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {teams.map((team) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  onClick={() => {
                    setPermission(team);
                    setOpen(true);
                  }}
                  variant="contained"
                  fullWidth
                  style={{ padding: 20, borderRadius: 10 }}
                >
                  <Typography>{team.name}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>

      <Modal
        open={open}
        close={() => {
          setOpen(false);
          setPermission(null);
        }}
        att={() => getTeams()}
        PrePermission={permission}
      />

      <ModalAdd
        open={openCreate}
        close={() => setOpenCreate(false)}
        att={() => getTeams()}
      />
    </Container>
  );
}

export default Tasks;
