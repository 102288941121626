import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../../../../components/Input';
// import { Container } from './styles';

const ModalAdd = ({ open, close, att, team, user }) => {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [level, setLevel] = React.useState('coordenador');

  async function addUser(e) {
    try {
      setLoading(true);

      await api.put(`/user/level/${team}`, {
        userId: user._id,
        newLevel: level,
      });

      toast.success('Usuario mudou de nivel na equipe com sucesso.');

      close();
      att();

      setLoading(false);
    } catch (err) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');

      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={() => close()}>
      <Grid container spacing={2} style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Adicionar usuario na equipe</Typography>
        </Grid>
        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={addUser}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={level}
              variant="outlined"
              style={{ width: '100%' }}
              onChange={(e) => setLevel(e.target.value)}
            >
              <MenuItem value={'coordenador'}>Coordenador</MenuItem>
              <MenuItem value={'participante'}>Participante</MenuItem>
            </Select>

            <Button
              disabled={loading}
              fullWidth
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              type="submit"
            >
              {loading ? <CircularProgress /> : 'Adicionar'}
            </Button>
          </Form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalAdd;
