/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
import { Pagination } from "@material-ui/lab";
import React from "react";
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import { toast } from "react-toastify";
import useStyles from "./styles";
import api from "../../../services/api";
import history from "../../../services/history";

import { Form } from "@unform/web";
import Input from "../../../components/Input";
import { TeamPoints, TeamPoints2 } from "./components/TeamPoints";

function Tasks() {
  const formRef = React.useRef();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [total, setTotal] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 1,
  });
  const [data2, setData2] = React.useState({
    lobinho: {
      bronze: {
        min: 0,
        max: 500,
      },
      prata: {
        min: 501,
        max: 700,
      },
      ouro: {
        min: 701,
        max: 1050,
      },
      diamante: {
        min: 1051,
        max: 2000,
      },
    },
    escoteiro: {
      bronze: {
        min: 0,
        max: 500,
      },
      prata: {
        min: 501,
        max: 700,
      },
      ouro: {
        min: 701,
        max: 1050,
      },
      diamante: {
        min: 1051,
        max: 2000,
      },
    },
    senior: {
      bronze: {
        min: 0,
        max: 500,
      },
      prata: {
        min: 501,
        max: 700,
      },
      ouro: {
        min: 701,
        max: 1050,
      },
      diamante: {
        min: 1051,
        max: 2000,
      },
    },
    pioneiro: {
      bronze: {
        min: 0,
        max: 500,
      },
      prata: {
        min: 501,
        max: 700,
      },
      ouro: {
        min: 701,
        max: 1050,
      },
      diamante: {
        min: 1051,
        max: 2000,
      },
    },
  });
  const [loading3, setLoading3] = React.useState(false);
  const [data3, setData3] = React.useState(null);

  async function getTeams(page) {
    try {
      setLoading(true);
      const response = await api.get("/team", {
        params: {
          filter: search,
          perPage: 10,
          page: page || 1,
        },
      });

      setTeams(response.data.data);
      setTotal(response.data.totalData);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading(false);
    }
  }

  async function selectTeam(team) {
    try {
      setLoading2(true);

      history.push(`/team/${team._id}`);

      setLoading2(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading2(false);
    }
  }

  const handleChange = (event, value) => {
    getTeams(value);
  };

  async function getData2(e) {
    try {
      setLoading3(true);

      const response = await api.post("/answers/medias", {
        lobinho: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        escoteiro: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        senior: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        pioneiro: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        ...e,
      });

      setData2(response.data);

      setLoading3(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoading3(false);
    }
  }

  async function sendCalc() {
    try {
      setLoading3(true);
      const allData = formRef.current.getData();

      await api.post("/answers/grade", {
        lobinho: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        escoteiro: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        senior: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        pioneiro: {
          bronze: {
            min: 0,
            max: 500,
          },
          prata: {
            min: 501,
            max: 700,
          },
          ouro: {
            min: 701,
            max: 1050,
          },
          diamante: {
            min: 1051,
            max: 2000,
          },
        },
        ...allData,
      });

      getData2(allData);

      getTeams(1);

      setLoading3(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoading3(false);
    }
  }

  async function getMetrics() {
    try {
      const response = await api.get("/super/metrics");

      setData3(response.data);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  async function troglePublic() {
    try {
      setLoading3(true);

      const response = await api.post("/super/metrics", {
        publicar: !data3.public,
      });

      setData3(response.data);

      setLoading3(false);
    } catch (error) {
      setLoading3(false);
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  React.useEffect(() => {
    getTeams(1);
    getData2(null);
    getMetrics();
  }, []);

  return (
    <Container>
      <Paper className={classes.paper} style={{ marginBottom: 20 }}>
        <Form
          ref={formRef}
          initialData={{
            lobinho: {
              bronze: {
                min: `${0}`,
                max: 500,
              },
              prata: {
                min: 501,
                max: 700,
              },
              ouro: {
                min: 701,
                max: 1050,
              },
              diamante: {
                min: 1051,
                max: 2000,
              },
            },
            escoteiro: {
              bronze: {
                min: `${0}`,
                max: 500,
              },
              prata: {
                min: 501,
                max: 700,
              },
              ouro: {
                min: 701,
                max: 1050,
              },
              diamante: {
                min: 1051,
                max: 2000,
              },
            },
            senior: {
              bronze: {
                min: `${0}`,
                max: 500,
              },
              prata: {
                min: 501,
                max: 700,
              },
              ouro: {
                min: 701,
                max: 1050,
              },
              diamante: {
                min: 1051,
                max: 2000,
              },
            },
            pioneiro: {
              bronze: {
                min: `${0}`,
                max: 500,
              },
              prata: {
                min: 501,
                max: 700,
              },
              ouro: {
                min: 701,
                max: 1050,
              },
              diamante: {
                min: 1051,
                max: 2000,
              },
            },
          }}
          onSubmit={getData2}
        >
          <Grid container spacing={3}>
            <TeamPoints data2={data2}/>

            <Grid item xd>
              <Button
                variant="contained"
                color="primary"
                disabled={loading3}
                type="submit"
              >
                Calcular
              </Button>
            </Grid>

            <Grid item xd>
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendCalc()}
                disabled={loading3}
              >
                Salvar
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography>Metricas Salvas</Typography>
            </Grid>

           {data3 && (
              <>
                <Grid item xs={12}>
                  {`status: ${data3.public ? "publicado" : "não publicado"}`}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={() => troglePublic()}
                    variant="contained"
                    color="primary"
                  >
                    {data3.public ? "Despublicar" : "Publicar"}
                  </Button>
                </Grid>

               <TeamPoints2 data3={data3} />
              </>
            )} 
          </Grid>
        </Form>
      </Paper>
      <Paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Equipes
                    {total && (
                      <Typography
                        style={{ marginLeft: 10 }}
                      >{`total: ${total}`}</Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        label="Pesquisar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading2}
                        onClick={() => getTeams()}
                      >
                        {loading2 ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "#ffff" }}
                          />
                        ) : (
                          "Atualizar"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell align="center">Tipo</TableCell>
                      <TableCell align="center">Grupo</TableCell>
                      <TableCell align="center">Pontos</TableCell>
                      <TableCell align="center">Classificação</TableCell>
                      <TableCell align="right">Açoes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.map((team) => (
                      <TableRow key={team._id}>
                        <TableCell component="th" scope="row">
                          {team.name}
                        </TableCell>
                        <TableCell align="center">{team.type}</TableCell>
                        <TableCell align="center">{`${team.groupName} - ${team.groupNumber}`}</TableCell>
                        <TableCell align="center">{team.grade}</TableCell>
                        <TableCell align="center">
                          {team.classification}
                        </TableCell>

                        <TableCell align="right">
                          <IconButton onClick={() => selectTeam(team)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
}

export default Tasks;
