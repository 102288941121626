import * as Yup from 'yup';

export default async (data) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Por favor, digite o nome da equipe')
      .min(3, 'Minimo de 3 caracteres'),
  });

  await schema.validate(data, { abortEarly: false });
};
