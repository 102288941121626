import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  paper: {
    padding: "80px 200px",
    marginBottom: 50,

    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
  },

  title: {
    color: "#363636",
    letterSpacing: 0.6,
    textTransform: "capitalize",
    fontFamily: "Source Sans Pro",

    fontSize: 28,
    fontWeight: 600,
  },

  media: {
    width: "100%",
    height: 250,
    background: "#363636",
    borderRadius: 5,

    [theme.breakpoints.down("md")]: {
      height: 150,
    },
  },

  subTitle: {
    color: "#363636",
    letterSpacing: 0.6,
    textTransform: "none",
    fontFamily: "Source Sans Pro",

    fontSize: 27,
    fontWeight: 400,
  },

  label: {
    color: "#363636",
    letterSpacing: 0.6,
    textTransform: "capitalize",
    fontFamily: "Source Sans Pro",

    fontSize: 21,
    fontWeight: 600,
  },

  value: {
    color: "#363636",
    letterSpacing: 0.54,
    textTransform: "none",
    fontFamily: "Source Sans Pro",

    fontSize: 18,
    fontWeight: 300,
  },

  status: {
    color: "#363636",
    letterSpacing: 0.54,
    textTransform: "capitalize",
    fontFamily: "Source Sans Pro",
    display: "flex",
    flexDirection: "column",
    fontSize: 18,
    fontWeight: 300,
  },

  answer: {
    color: "#363636",
    letterSpacing: 0.54,
    textTransform: "capitalize",
    fontFamily: "Source Sans Pro",

    fontSize: 18,
    fontWeight: 400,
  },

  button: {
    fontFamily: "Source Sans Pro",
    color: "#fff",
    fontWeight: 400,
    background: "#664ACF",
    fontSize: 18,
    textTransform: "capitalize",
    "&:hover": {
      background: darken(0.08, "#664ACF"),
    },

    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },

  red: {
    background: "#f44336",

    "&:hover": {
      background: darken(0.08, "#f44336"),
    },
  },
}));
