import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import api from '../../../../../services/api';
import validator from './validator';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Input from '../../../../../components/Input';
// import { Container } from './styles';

const ModalAdd = ({ open, close, att, team }) => {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  async function addUser(e) {
    formRef.current.setErrors({});

    try {
      setLoading(true);

      await validator(e);

      await api.post(`/team/join-force`, {
        register: e.userCode,
        teamId: team,
      });

      toast.success('Usuario adicionado na equipe com sucesso.');

      close();
      att();

      setLoading(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error('Ocorreu algum erro, tente novamente mais tarde.');
      }

      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={() => close()}>
      <Grid container spacing={2} style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Adicionar usuario na equipe</Typography>
        </Grid>
        <Grid item xs={12}>
          <Form ref={formRef} onSubmit={addUser}>
            <Input name="userCode" label="Codigo do usuario" />

            <Button
              disabled={loading}
              fullWidth
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              type="submit"
            >
              {loading ? <CircularProgress /> : 'Adicionar'}
            </Button>
          </Form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalAdd;
