import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/Auth/SignIn";

import Dashboard from "../pages/App/Dashboard";
import Users from "../pages/App/Users";
import Agents from "../pages/App/Agents";
import Tasks from "../pages/App/Tasks";
import Create from "../pages/App/Tasks/Create";
import Teams from "../pages/App/Teams";
import TeamsEdit from "../pages/App/Teams/Edit";
import Permission from "../pages/App/Permission";
import Answer from "../pages/App/Answer";

export default function Routes() {
  return (
    <Switch>
      {/* Auth Routes */}
      <Route path="/" exact component={SignIn} />

      {/* Academy Routes */}
      <Route path="/dashboard" exact isPrivate component={Dashboard} />
      <Route path="/user" isPrivate component={Users} />
      <Route path="/agent" isPrivate component={Agents} />
      <Route path="/task/create" exact isPrivate component={Create} />
      <Route path="/task" isPrivate component={Tasks} />
      <Route path="/answer" isPrivate component={Answer} />
      <Route path="/team/:id" exact isPrivate component={TeamsEdit} />
      <Route path="/team" exact isPrivate component={Teams} />
      <Route path="/permission" exact isPrivate component={Permission} />
    </Switch>
  );
}
