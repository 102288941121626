import React from "react";

import Input from "../../../../components/Input";
import {
  Typography,
  Grid,
} from "@material-ui/core";

const types = ["lobinho", "escoteiro", "senior", "pioneiro"];
const levels = ["bronze", "prata", "ouro", "diamante"];

export const TeamPoints = ({ data2 }) => {
  return levels.map((level) => (
    <Grid item xs={12} sm={6} md={3}>
      <Grid
        container
        spacing={2}
        style={{ border: "1px solid #000", borderRadius: 10 }}
      >
        <Grid item xs={12}>
          <Typography>{level}</Typography>
        </Grid>
        {types.map((type) => (
          <>
            <Grid item xs={12}>
              <Typography>{type}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input name={`${type}.${level}.min`} label="min" />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <Input name={`${type}.${level}.max`} label="max" />
            </Grid>
          </>
        ))}

        {types.map((type) => (
          <Grid item xs={12}>
            <Typography>
              {`Total ${type}: ${data2?.[type] ? data2?.[type]?.[level] : 0}`}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ));
};

export const TeamPoints2 = ({ data3 }) => {
  return levels.map((level) => (
    <Grid item xs={12} sm={6} md={3}>
      <Grid
        container
        spacing={2}
        style={{ border: "1px solid #000", borderRadius: 10 }}
      >
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold" }}>{level}</Typography>
        </Grid>

        {types.map((type, index) => (
          <Grid item xs={12}>
            <div
              style={{ borderTop: index === 0 ? undefined : "1px solid black" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>{type}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{`Min: ${data3?.[type]?.[level].min}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>{`Max: ${data3?.[type]?.[level].max}`}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ));
};
