/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Checkbox,
  TextField,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as Yup from 'yup';
import validator from './validator';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Modal({ open, close, att, PrePermission }) {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [loadingTasks, setLoadingTaks] = React.useState(true);
  const [tasks, setTasks] = React.useState([]);
  const [allTasks, setAllTasks] = React.useState([]);
  const [name, setName] = React.useState('');
  const [permission, setPermission] = React.useState(null);
  const [superPermission, setSuperPermission] = React.useState(false);

  async function getPermission() {
    try {
      setLoading(true);

      const response = await api.get(`/permissons/${PrePermission._id}`);

      setTasks(response.data.tasks);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');
      close();
    }
  }

  async function getTasks() {
    try {
      setLoadingTaks(true);

      const response = await api.get(`/tasks`);

      setAllTasks(response.data.data);

      setLoadingTaks(false);
    } catch (error) {
      console.log(error);

      setLoadingTaks(false);
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');
      close();
    }
  }

  React.useEffect(() => {
    if (open && PrePermission) {
      getPermission();
      getTasks();
      setName(PrePermission.name);
      setSuperPermission(PrePermission.super);
    }
  }, [open]);

  async function submit(e) {
    formRef.current.setErrors({});

    try {
      setLoading(true);

      await validator({ name });

      await api.put(`/permissons/${PrePermission._id}`, {
        super: superPermission,
        name,
        tasks: tasks.map((item) => item._id),
      });

      toast.success('Equipe alterada com sucesso.');

      close();
      att();

      setLoading(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error('Ocorreu algum erro, tente novamente mais tarde.');
      }

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setTasks([]);
        close();
      }}
    >
      <Grid container spacing={2} style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Editar {PrePermission ? PrePermission.name : ''}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <React.Fragment>
            <Checkbox
              onClick={() => setSuperPermission(!superPermission)}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={superPermission}
            />
            Super
          </React.Fragment>
        </Grid>
        <Grid item xs={12}>
          <Form ref={formRef} initialData={PrePermission} onSubmit={submit}>
            <Input
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Nome do grupo"
            />

            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={allTasks}
              disableCloseOnSelect
              onChange={(event, value) => setTasks(value)}
              value={tasks}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(item) => {
                let flag = false;

                tasks.forEach((task) => {
                  if (String(task._id) === String(item._id)) flag = true;
                });

                return flag;
              }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {`${option.title} - ${option.type}`}
                </React.Fragment>
              )}
              style={{ width: '100%', marginTop: 20 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tarefas"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Form>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={() => formRef.current.submitForm()}
            disabled={loading || loadingTasks}
            variant="contained"
            color="primary"
            fullWidth
          >
            {loading || loadingTasks ? <CircularProgress /> : 'Alterar'}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default Modal;
