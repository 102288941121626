import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    padding: 20,
    borderRadius: 5,
    background: '#FBFBFB',
    boxShadow: ' 3px 3px 4px #0000000F',
  },

  title: {
    fontSize: 32,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },

  info: {
    fontSize: 27,
    width: '100%',
    textAlign: 'center',
    padding: 20,
  },
}));
