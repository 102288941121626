import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export default makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: 50,
    boxShadow: "0px 3px 6px #00000029",

    [theme.breakpoints.down("md")]: {
      padding: 25,
    },
  },
  logo: {
    margin: "0 auto",
    maxHeight: 180,

    [theme.breakpoints.down("md")]: {
      maxHeight: 80,
    },
  },
  description: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.48,
    color: "#363636",
    opacity: 1,
    textAlign: "center",
  },
  descriptionDiv: {
    marginBottom: 30,
  },
  btn1: {
    fontFamily: "Source Sans Pro",
    color: "#fff",
    fontWeight: 600,
    background: "#744EAA",
    fontSize: 20,
    textTransform: "capitalize",
    "&:hover": {
      background: darken(0.08, "#744EAA"),
    },
    marginTop: 20,
    marginBottom: 30,

    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  btn2: {
    fontFamily: "Source Sans Pro",
    color: "#483699",
    fontWeight: 600,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    textTransform: "capitalize",
    fontSize: 20,

    [theme.breakpoints.down("md")]: {
      background: "none",
      boxShadow: "none",
      fontSize: 18,

      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  copyright: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    textAlign: "center",
    marginTop: 50,
    fontSize: 20,
    letterSpacing: 0.6,
    color: "#484848",
    fontWeight: 400,
  },

  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0",
  },

  white: {
    color: "#fff",
    marginTop: 25,
    fontSize: 16,
  },

  form: {
    width: "100%",
  },
}));
