/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sparse-arrays */
import { Pagination } from '@material-ui/lab';

import React from 'react';
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { toast } from 'react-toastify';
import useStyles from './styles';
import api from '../../../services/api';
import history from '../../../services/history';
import Modal from './Modal';

function Tasks() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [total, setTotal] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [user, setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 1,
  });

  async function getUsers(page) {
    try {
      setLoading(true);
      const response = await api.get('/user/list', {
        params: {
          filter: search,
          page: page || 1,
          perPage: 10,
        },
      });

      setUsers(response.data.data);
      setTotal(response.data.totalData);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });

      setLoading(false);
    } catch (error) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');

      setLoading(false);
    }
  }

  async function selectUser(user) {
    try {
      setLoading2(true);
      const response = await api.get(`/user`, {
        params: {
          id: user._id,
        },
      });

      setUser(response.data);
      setOpen(true);

      setLoading2(false);
    } catch (error) {
      toast.error('Ocorreu algum erro, tente novamente mais tarde.');

      setLoading2(false);
    }
  }

  const handleChange = (event, value) => {
    getUsers(value);
  };

  React.useEffect(() => {
    getUsers(1);
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Participantes
                    {total && (
                      <Typography
                        style={{ marginLeft: 10 }}
                      >{`total: ${total}`}</Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        label="Pesquisar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading2}
                        onClick={() => getUsers()}
                      >
                        {loading2 ? (
                          <CircularProgress
                            size={20}
                            style={{ color: '#ffff' }}
                          />
                        ) : (
                          'Atualizar'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell align="center">Registro</TableCell>
                      <TableCell align="center">Regiao</TableCell>
                      <TableCell align="right">Grupo</TableCell>
                      <TableCell align="right">Açoes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell align="center">{user.register}</TableCell>
                        <TableCell align="center">{user.region}</TableCell>
                        <TableCell align="center">
                          {Number(user.groupNumber) === 0
                            ? 'Externo'
                            : user.groupNumber}
                        </TableCell>

                        <TableCell align="right">
                          <IconButton onClick={() => selectUser(user)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12}>
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
      </Paper>

      <Modal
        open={open}
        close={() => {
          setUser(false);
          setOpen(false);
        }}
        Att={() => getUsers()}
        data={user}
      />
    </Container>
  );
}

export default Tasks;
