/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Dialog,
  Typography,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Checkbox,
  TextField,
} from "@material-ui/core";
import api from "../../../../services/api";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { toast } from "react-toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// import { Container } from './styles';

function Modal({ open, close, Att, data }) {
  const [type, setType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [loadingPermissions, setLoadingPermission] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [permission, setPermission] = React.useState(null);
  const [loadingUpdatePaxtu, setLoadingUpdatePaxtu] = React.useState(false);

  async function getPermissions() {
    try {
      setLoadingPermission(true);

      const response = await api.get("/permissons");

      console.log(response.data.data);
      console.log(permission);

      setPermissions(response.data.data);

      setLoadingPermission(false);
    } catch (error) {
      setLoadingPermission(false);
    }
  }

  async function submit() {
    try {
      setLoading(true);

      let query = {
        role: type,
      };

      if (permission) {
        query.permissons = permission._id;
      } else {
        query.permissons = "exclue";
      }

      await api.put(`/user/${data._id}`, query);

      toast.success("User alterado com sucesso");

      Att();
      close();
      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (open && data) {
      setType(data.role);

      if (data.permissons) {
        setPermission(data.permissons);
      }
    }
  }, [data, open]);

  React.useEffect(() => {
    if (type !== "user") {
      getPermissions();
    }
  }, [type]);

  async function updatePaxtu() {
    try {
      setLoadingUpdatePaxtu(true);

      await api.put(`/user/updatePaxtu/${data.register}`);

      toast.success("Usuario atualizado com sucesso");

      setLoadingUpdatePaxtu(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoadingUpdatePaxtu(false);
    }
  }

  console.log(data);
  return (
    <Dialog open={open} onClose={() => close()} style={{ padding: 20 }}>
      {data && (
        <div style={{ height: "100%", width: "100%", padding: 20 }}>
          <Typography>Nome: {data.name}</Typography>
          <Typography>Registro: {data.register}</Typography>
          <Typography>Regiao: {data.region}</Typography>
          <Typography>
            Grupo:{" "}
            {Number(data.groupNumber) === 0 ? "Externo" : data.groupNumber}
          </Typography>
          <Typography>Data de nacimento: {data.date}</Typography>
          <Typography>Email: {data.email}</Typography>
          <Typography>
            Equipe:{" "}
            {data.team && data.team.teamId && data.team.teamId._id
              ? data.team.teamId._id
              : "Sem equipe"}
          </Typography>
          {data.team && data.team.teamId ? (
            <>
              <Typography>id: {data.team.teamId._id}</Typography>
              <Typography>Nome: {data.team.teamId.name}</Typography>
              <Typography>Tipo: {data.team.teamId.type}</Typography>
            </>
          ) : (
            ""
          )}
          {data.permissons && (
            <Typography>Permissão: {data.permissons.name}</Typography>
          )}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            variant="outlined"
            style={{ width: "100%", marginTop: 20 }}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={"admin"}>Admin</MenuItem>
            <MenuItem value={"agent"}>Corretor</MenuItem>
            <MenuItem value={"user"}>Usuario</MenuItem>
          </Select>
          {type !== "user" && (
            <Autocomplete
              id="free-solo-2-demo"
              onChange={(event, value) => {
                setPermission(value);
              }}
              value={permission}
              getOptionLabel={(option) => option.name}
              options={permissions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupo de correção"
                  margin="normal"
                  variant="outlined"
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
            />
          )}
          <Button
            onClick={() => submit()}
            disabled={loading}
            fullWidth
            style={{ marginTop: 20 }}
            variant="contained"
            color="primary"
          >
            {loading ? <CircularProgress /> : "Alterar"}
          </Button>

          <Button
            onClick={() => updatePaxtu()}
            disabled={loadingUpdatePaxtu}
            fullWidth
            style={{ marginTop: 20 }}
            variant="contained"
            color="primary"
          >
            {loadingUpdatePaxtu ? (
              <CircularProgress />
            ) : (
              "Atualizar email do paxtu"
            )}
          </Button>
        </div>
      )}
    </Dialog>
  );
}

export default Modal;
