import axios from "axios";

const api = axios.create({
  //baseURL: "http://localhost:4000",
  //baseURL: "https://plataforma-joti.herokuapp.com/",
  //baseURL: "https://1898-189-58-98-206.ngrok-free.app",
  baseURL: "https://api.jotajoti.com.br",
});

export default api;
