import React from "react";
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
} from "@material-ui/core";
import { toast } from "react-toastify";
import useStyles from "./styles";
import api from "../../../services/api";
import history from "../../../services/history";
import { Task } from "./components/Task";

function Tasks() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [tasks, setTasks] = React.useState([]);

  async function getTasks() {
    try {
      setLoading(true);
      const response = await api.get("/tasks", {
        params: {
          withQtd: true,
        },
      });

      setTasks(response.data.data);

      setLoading(false);
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");

      setLoading(false);
    }
  }

  async function deleteTask(task) {
    try {
      setLoading(true);
      await api.delete(`/tasks/${task}`);

      toast.success("Tarefa excluida com sucesso.");

      setLoading(false);
      getTasks();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      }

      setLoading(false);
    }
  }

  React.useEffect(() => {
    getTasks();
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography className={classes.title}>Tarefas</Typography>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/task/create")}
                  >
                    Criar tarefa
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Numero</TableCell>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">Sessao</TableCell>
                      <TableCell align="center">Hora de liberação</TableCell>
                      <TableCell align="center">
                        Respostas nao corrigidas
                      </TableCell>

                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks
                      .sort((a, b) => {
                        const nameA = a.type.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.type.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }

                        // names must be equal
                        return 0;
                      })
                      .map((task) => (
                        <Task task={task} deleteTask={deleteTask} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
}

export default Tasks;
